import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesWindowInfoViewComponent } from './sales-window-info-view.component';
import {
  NzGridModule,
  NzTableModule,
  NzTabsModule,
  NzCarouselModule,
  NzButtonModule,
  NzTagModule,
  NzIconModule,
  NzModalModule,
} from 'ng-zorro-antd';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { MainPipeModule } from '../main-pipe/main-pipe.module';
import {
  OverlayContainer,
  FullscreenOverlayContainer,
} from '@angular/cdk/overlay';
@NgModule({
  imports: [
    NzIconModule,
    CommonModule,
    NzGridModule,
    NzGridModule,
    NzTableModule,
    NzTagModule,
    NzTabsModule,
    NzCarouselModule,
    NzDividerModule,
    NzButtonModule,
    MainPipeModule,
    NzModalModule
  ],
  declarations: [SalesWindowInfoViewComponent],
  exports: [SalesWindowInfoViewComponent],
  entryComponents: [SalesWindowInfoViewComponent],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
})
export class SalesWindowInfoViewModule {}
