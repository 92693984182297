import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PermissionService } from './auth/permission.service';
import { SigninService } from './auth/signin.service';
import { StorageService } from './storage.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root',
})
export class AuthguardService implements CanActivate {
  constructor(
    private storageService: StorageService,
    private permissionService: PermissionService,
    private router: Router,
    private modalService: NzModalService,
    private signinService: SigninService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    
    const requiredPermissions = route.data['permission'];


    const permissionsArray: string[] =
      Array.isArray(requiredPermissions) ? requiredPermissions : requiredPermissions ? [requiredPermissions] : [];

    // Ensure it's an array
    let url = state.url.startsWith('/') ? state.url.substring(1) : state.url;
    this.storageService.setVisitedURL(state.url);

    if (this.storageService.isAuthenticated() && !this.permissionService.hasPermission('home')) {
      this.signinService.doLogout();
      this.router.navigate(['/account/signin']);
      return of(false);
    } else if (this.storageService.isChangePasswordActive()) {
      this.router.navigate(['/account/signin']);
      return of(false);
    }
    if (!this.storageService.isAuthenticated() || !this.permissionService.hasPermission('ims:sales_login')) {
      this.router.navigate(['/account/signin']);
      return of(false);
    }
    // ✅ Check if user has at least one required permission
    if (permissionsArray.length > 0 && !permissionsArray.some((perm) => this.permissionService.hasPermission(perm))) {
      this.modalService.error({
        nzTitle: 'Permission Denied',
        nzContent: 'You are not authorized to access this page',
        nzOkText: 'OK',
        nzOnOk: () => this.router.navigate(['/home']),
      });
      return of(false);
    }
    return of(true);
  }

}